import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FilesService {

  //private _url = "/api/files/";
  private _url = environment.ApiUrl + "/files";
  private _headers = { 'content-type': 'application/json' }


  constructor(private http: HttpClient) { }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error);
  }


  get(codeCountry: string | null, category: string | null, module: string | null, activity: string | null, codePosRegion: string | null, codeChannel: string | null, codeSubChannel: string | null, codeChannelSegment: string | null, codeFormat: string | null, year: number | null, month: number | null, startDay: number | null, endDay: number | null, limit: number | null): Observable<any> {
    const body = JSON.stringify({
      "codeCountry": codeCountry,
      "category": category,
      "module": module,
      "activity": activity,
      "codeChannel": codeChannel,
      "codePosRegion": codePosRegion,
      "codeSubChannel": codeSubChannel,
      "codeChannelSegment": codeChannelSegment,
      "codeFormat": codeFormat,
     // "date": date,
      "year": year,
      "month": month,
      "dayInMonth": [startDay, endDay],
      "limit": limit
    });

    const headers = { ...this._headers, 'x-file-filters': body };
    console.log(headers);
    return this.http.get<any>(this._url, { 'headers': headers }).pipe(catchError(this.errorHandler));
  }
  //activity: string | null, codePosRegion:string startDay: number | null, endDay: number | null,

  //get(codeCountry: string | null, category: string | null, module: string | null, activity: string | null, codePosRegion:string | null, codeChannel: string | null, codeSubChannel: string | null, codeChannelSegment: string | null, codeFormat: string | null,
  //  date: Date | null, startDay: number | null, endDay: number | null, limit: number | null): Observable<any> {
  //  const headers = { 'content-type': 'application/json' };
  //  const body = JSON.stringify({
  //    "codeCountry": codeCountry,
  //    "category": category,
  //    "module": module,
  //    "activity": activity,
  //    "codePosRegion": codePosRegion,
  //    "codeChannel": codeChannel,
  //    "codeSubChannel": codeSubChannel,
  //    "codeChannelSegment": codeChannelSegment,
  //    "codeFormat": codeFormat,
  //    "date": date,
  //    "startDay": startDay,
  //    "endDay": endDay,
  //    "limit": limit
  //  });
  //  return this.http.post<any>(this._url + 'get', body, { 'headers': headers }).pipe(catchError(this.errorHandler));
  //}

}

