<div class="grid">
  <div class="col-12">
    <div class="card">
      <h5 class="noPrintable">Filters</h5>
      <div class="p-dataview p-component p-dataview-grid">
        <div class="p-dataview-header ng-star-inserted noPrintable">
          <div class="p-fluid">
            <div class="p-formgrid grid">
              <div class="col-12 md:col-3">
                <div class="p-inputgroup">
                  <span class="p-inputgroup-addon">Country</span>
                  <p-dropdown class="p-dropdown" [options]="_countries" optionLabel="cgcloud__Description__c" optionValue="Name" [(ngModel)]="countrySelected" (onChange)="filterChannelsByCountry(); filterPosRegionsByCountry();"></p-dropdown>
                  <p-button icon="pi pi-refresh" styleClass="p-button-rounded p-button-secondary p-button-text" *ngIf="_countries.length === 0 && !loadingCountries" (click)="getCountries();"></p-button>
                  <p-progressSpinner styleClass="w-3rem h-3rem" strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" *ngIf="loadingCountries"></p-progressSpinner>
                </div>
              </div>
              <div class="col-12 md:col-3">
                <div class="p-inputgroup">
                  <span class="p-inputgroup-addon">Month</span>
                  <p-calendar [(ngModel)]="dateSelected" view="month" dateFormat="mm/yy" [minDate]="minDate" [maxDate]="maxDate" [readonlyInput]="true"></p-calendar>
                </div>
              </div>
              <div class="col-12 md:col-3">
                <div class="p-inputgroup">
                  <span class="p-inputgroup-addon">Week</span>
                  <p-dropdown class="p-dropdown" [options]="_weeks" optionLabel="description" [(ngModel)]="weekSelected"></p-dropdown>
                  <p-button icon="pi pi-refresh" styleClass="p-button-rounded p-button-secondary p-button-text" *ngIf="_weeks.length === 0 && !loadingWeeks" (click)="getWeeksStartAndEndInMonth(dateSelected.getFullYear(), dateSelected.getMonth());"></p-button>
                  <p-progressSpinner styleClass="w-3rem h-3rem" strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" *ngIf="loadingWeeks"></p-progressSpinner>
                </div>
              </div>
              <div class="col-12 md:col-3">
                <div class="p-inputgroup">
                  <span class="p-inputgroup-addon">Module</span>
                  <p-dropdown class="p-dropdown" [options]="_modules" optionLabel="Name" optionValue="Name" [(ngModel)]="moduleSelected"></p-dropdown>
                  <p-button icon="pi pi-refresh" styleClass="p-button-rounded p-button-secondary p-button-text" *ngIf="_modules.length === 0 && !loadingModules" (click)="getModules();"></p-button>
                  <p-progressSpinner styleClass="w-3rem h-3rem" strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" *ngIf="loadingModules"></p-progressSpinner>
                </div>
              </div>
              <div class="col-12 md:col-3">
                <div class="p-inputgroup">
                  <span class="p-inputgroup-addon">Activity</span>
                  <p-dropdown class="p-dropdown" [options]="_activities" optionLabel="description" optionValue="id" [(ngModel)]="activitySelected"></p-dropdown>
                </div>
              </div>
              <div class="col-12 md:col-3">
                <div class="p-inputgroup">
                  <span class="p-inputgroup-addon">Pos Region</span>
                  <p-dropdown class="p-dropdown" [options]="_posRegionsFiltered" optionLabel="label" optionValue="KCC_Pos_Region__c" [(ngModel)]="posRegionSelected"></p-dropdown>
                  <p-button icon="pi pi-refresh" styleClass="p-button-rounded p-button-secondary p-button-text" *ngIf="_posRegionsFiltered.length === 0 && !loadingPosRegions" (click)="getPosRegions();"></p-button>
                  <p-progressSpinner styleClass="w-3rem h-3rem" strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" *ngIf="loadingPosRegions"></p-progressSpinner>
                </div>
              </div>
              <div class="col-12 md:col-3">
                <div class="p-inputgroup">
                  <span class="p-inputgroup-addon">Category</span>
                  <p-dropdown class="p-dropdown" [options]="_categories" optionLabel="label" optionValue="KCC_Business_Category__c" [(ngModel)]="categorySelected"></p-dropdown>
                  <p-button icon="pi pi-refresh" styleClass="p-button-rounded p-button-secondary p-button-text" *ngIf="_categories.length === 0 && !loadingCategories" (click)="getCategories();"></p-button>
                  <p-progressSpinner styleClass="w-3rem h-3rem" strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" *ngIf="loadingCategories"></p-progressSpinner>
                </div>
              </div>
              <div class="col-12 md:col-3">
                <div class="p-inputgroup">
                  <span class="p-inputgroup-addon">Channel</span>
                  <p-dropdown class="p-dropdown" [options]="_channelsFiltered" optionLabel="label" optionValue="KCC_POS_Channel__c" [(ngModel)]="channelSelected" (onChange)="filterSubChannelsByChannel();"></p-dropdown>
                  <p-button icon="pi pi-refresh" styleClass="p-button-rounded p-button-secondary p-button-text" *ngIf="_channelsFiltered.length === 0 && !loadingChannels" (click)="getChannels();"></p-button>
                  <p-progressSpinner styleClass="w-3rem h-3rem" strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" *ngIf="loadingChannels"></p-progressSpinner>
                </div>
              </div>
              <div class="col-12 md:col-3">
                <div class="p-inputgroup">
                  <span class="p-inputgroup-addon">Sub Channel</span>
                  <p-dropdown class="p-dropdown" [options]="_subChannelsFiltered" optionLabel="label" optionValue="KCC_POS_SubChannel__c" [(ngModel)]="subChannelSelected" (onChange)="filterChannelSegmentsBySubChannel();"></p-dropdown>
                  <p-button icon="pi pi-refresh" styleClass="p-button-rounded p-button-secondary p-button-text" *ngIf="_subChannelsFiltered.length === 0 && !loadingSubChannels" (click)="getSubChannels();"></p-button>
                  <p-progressSpinner styleClass="w-3rem h-3rem" strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" *ngIf="loadingSubChannels"></p-progressSpinner>
                </div>
              </div>
              <div class="col-12 md:col-3">
                <div class="p-inputgroup">
                  <span class="p-inputgroup-addon">Channel Segment</span>
                  <p-dropdown class="p-dropdown" [options]="_channelSegmentsFiltered" optionLabel="label" optionValue="KCC_POS_Channel_Segment__c" [(ngModel)]="channelSegmentSelected" (onChange)="filterFormatsByChannelSegment();"></p-dropdown>
                  <p-button icon="pi pi-refresh" styleClass="p-button-rounded p-button-secondary p-button-text" *ngIf="_channelSegmentsFiltered.length === 0 && !loadingCountries" (click)="getChannelSegments();"></p-button>
                  <p-progressSpinner styleClass="w-3rem h-3rem" strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" *ngIf="loadingChannelSegments"></p-progressSpinner>
                </div>
              </div>
              <div class="col-12 md:col-3">
                <div class="p-inputgroup">
                  <span class="p-inputgroup-addon">Format</span>
                  <p-dropdown class="p-dropdown" [options]="_formatsFiltered" optionLabel="label" optionValue="KCC_Format__c" [(ngModel)]="formatSelected"></p-dropdown>
                  <p-button icon="pi pi-refresh" styleClass="p-button-rounded p-button-secondary p-button-text" *ngIf="_formatsFiltered.length === 0 && !loadingCountries" (click)="getFormats();"></p-button>
                  <p-progressSpinner styleClass="w-3rem h-3rem" strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" *ngIf="loadingFormats"></p-progressSpinner>
                </div>
              </div>
              <!--<div class="col-12 md:col-3">
    <div class="p-inputgroup">
      <span class="p-inputgroup-addon">KPI</span>
      <p-dropdown class="p-dropdown" [options]="_kpis" optionLabel="name" optionValue="name" [(ngModel)]="kpiSelected"></p-dropdown>

    </div>
  </div>-->
              <!--<div class="col-12 md:col-3">
    <div class="p-inputgroup">
      <span class="p-inputgroup-addon">Tag</span>
      <p-dropdown class="p-dropdown" [options]="_tags" optionLabel="description" optionValue="description" [(ngModel)]="tagSelected"></p-dropdown>

    </div>
  </div>-->
              <!--<div class="col-12 md:col-3">
    <div class="p-inputgroup">
      <span class="p-inputgroup-addon">Tag</span>
      <p-dropdown class="p-dropdown" [options]="_formatsFiltered" optionLabel="label" optionValue="KCC_Format__c" [(ngModel)]="formatSelected"></p-dropdown>

    </div>
  </div>-->
              <div class="col-12 md:col-3">
                <div class="p-inputgroup">
                  <span class="p-inputgroup-addon">Limit</span>
                  <p-dropdown class="p-dropdown" [options]="_limits" optionLabel="description" optionValue="id" [(ngModel)]="limitSelected"></p-dropdown>

                </div>
              </div>
              <div class="col-4 md:col-1">
                <button type="button" pButton pRipple class="p-element p-button p-component" (click)="getFiles();" [disabled]="loadingImages">
                  <span class="p-button-label"><i class="pi pi-search"></i></span>
                </button>
              </div>
              <div class="col-4 md:col-1">
                <button pbutton="" pripple="" (click)="print();" [disabled]="loadingImages"
                        class="p-element p-button p-component">
                  <span class="p-button-label"> <i class="pi pi-download"></i></span>
                </button>
              </div>
              <div class="col-4 md:col-1">
                &nbsp;
              </div>
              <div class="col-4 md:col-3" style="text-align: right">
                <label class="normalText">Select all to print </label>
                <p-checkbox [(ngModel)]="checked" [binary]="true" inputId="binary" (onChange)="checkItemsToPrint();"></p-checkbox>
              </div>
            </div>
          </div>
          <div class="p-fluid">
            <div class="p-formgrid grid">
              <div class="col-12 md:col-10">
                <p class="normalText" *ngIf="_countries.length > 0">
                  <!--<br>
            You are seeing results for country <span class="resaltedText">{{countrySelectedSearch}}</span> and year
            <span class="resaltedText">{{yearSelectedSearch}}</span>-->
                </p>
              </div>
            </div>
          </div>
        </div>
        <br class="noPrintable" />
        <div class="p-dataview-header ng-star-inserted noPrintable" [hidden]="_filesFinal.length === 0">
          <div class="p-fluid">
            <div class="p-formgrid grid">
              <div class="col-12 md:col-6">
                <label htmlfor="firstname2">Customer</label>
                <p-multiSelect [options]="_customers" [(ngModel)]="selectedCustomers" [filter]="true" optionLabel="name" display="chip" (onChange)="filterFiles();"></p-multiSelect>
              </div>
              <div class="col-12 md:col-6">
                <label htmlfor="firstname2">POS</label>
                <p-multiSelect [options]="_pos" [(ngModel)]="selectedPos" [filter]="true" optionLabel="name" display="chip" (onChange)="filterFiles();"></p-multiSelect>
              </div>
              <div class="col-12 md:col-3">
                <label htmlfor="firstname2">Display Group</label>
                <p-multiSelect [options]="_displayGroups" [(ngModel)]="selectedDisplayGroups" [filter]="true" optionLabel="description" display="chip" (onChange)="filterFiles();"></p-multiSelect>
                <!--<p-dropdown [options]="_displayGroups" optionLabel="description" optionValue="description" [(ngModel)]="displayGroupSelected" class="p-element p-inputwrapper" (onChange)="filterFiles();"></p-dropdown>-->
              </div>
              <div class="col-12 md:col-3">
                <label htmlfor="firstname2">Display Type</label>
                <p-multiSelect [options]="_displayTypes" [(ngModel)]="selectedDisplayTypes" [filter]="true" optionLabel="description" display="chip" (onChange)="filterFiles();"></p-multiSelect>
                <!--<p-dropdown [options]="_displayTypes" optionLabel="description" optionValue="description" [(ngModel)]="displayTypeSelected" class="p-element p-inputwrapper p-inputwrapper-filled" (onChange)="filterFiles();"></p-dropdown>-->
              </div>
              <div class="col-12 md:col-6">
                <label htmlfor="firstname2">Responsible</label>
                <p-multiSelect [options]="_responsibles" [(ngModel)]="selectedResponsibles" [filter]="true" optionLabel="name" display="chip" (onChange)="filterFiles();"></p-multiSelect>
              </div>
            </div>
          </div>
        </div>
        <br class="noPrintable" />
        <div *ngIf="_filesFinal.length === 0 && !loadingImages">
          No images found for this filters.
        </div>
        <div *ngIf="loadingImages" class="noPrintable">
          <div class="grid p-fluid">
            <div class="field col-12 md:col-3 noPrintable">

              <p-skeleton width="23rem" height="15rem" styleClass="mb-2"></p-skeleton>

              <p-skeleton width="15rem" styleClass="mb-2"></p-skeleton>

              <p-skeleton width="10rem" styleClass="mb-2"></p-skeleton>

              <p-skeleton width="8rem" styleClass="mb-2"></p-skeleton>

            </div>
            <div class="field col-12 md:col-3 noPrintable">

              <p-skeleton width="23rem" height="15rem" styleClass="mb-2"></p-skeleton>

              <p-skeleton width="15rem" styleClass="mb-2"></p-skeleton>

              <p-skeleton width="10rem" styleClass="mb-2"></p-skeleton>

              <p-skeleton width="8rem" styleClass="mb-2"></p-skeleton>

            </div>
            <div class="field col-12 md:col-3 noPrintable">

              <p-skeleton width="23rem" height="15rem" styleClass="mb-2"></p-skeleton>

              <p-skeleton width="15rem" styleClass="mb-2"></p-skeleton>

              <p-skeleton width="10rem" styleClass="mb-2"></p-skeleton>

              <p-skeleton width="8rem" styleClass="mb-2"></p-skeleton>

            </div>
            <div class="field col-12 md:col-3 noPrintable">

              <p-skeleton width="23rem" height="15rem" styleClass="mb-2"></p-skeleton>

              <p-skeleton width="15rem" styleClass="mb-2"></p-skeleton>

              <p-skeleton width="10rem" styleClass="mb-2"></p-skeleton>

              <p-skeleton width="8rem" styleClass="mb-2"></p-skeleton>

            </div>
          </div>
        </div>

          <div class="grid p-fluid" *ngIf="!loadingImages">
            <div class="field col-12 md:col-3" *ngFor="let file of _filesFiltered;" [ngClass]="file.checked ? '' : 'noPrintable' ">
              <div class="imgPrintWidth">
                <img src="{{file.url}}" style="width: 280px" (click)="showDialog(file)" />
              </div>
              <div class="textPrintWidth">
                <p class="m-0">
                  <b>Title:</b> {{file.imageTitle}}
                </p>
                <p class="m-0">
                  <b>POS:</b> {{file.pos}}
                </p>
                <p class="m-0">
                  <b>Customer:</b> {{file.customer}}
                </p>
                <p class="m-0">
                  <b>Account number:</b> {{file.accountNumber}}
                </p>
                <p class="m-0">
                  <b>Country:</b> {{file.country}}
                </p>
                <p class="m-0">
                  <b>Pos Region:</b> {{file.posRegionName}}
                </p>
                <p class="m-0">
                  <b>Channel:</b> {{file.posChannelName}}
                </p>
                <p class="m-0">
                  <b>Sub Channel:</b> {{file.posSubChannelName}}
                </p>
                <p class="m-0">
                  <b>Channel Segment:</b> {{file.posChannelSegmentName}}
                </p>
                <p class="m-0">
                  <b>Format:</b> {{file.formatName}}
                </p>
                <p class="m-0">
                  <b>Category:</b> {{file.categoryName}}
                </p>
                <p class="m-0">
                  <b>
                    Module:
                  </b> {{file.module}}
                </p>
                <p class="m-0">
                  <b>
                    Activity:
                  </b> {{file.activity}}
                </p>
                <p class="m-0">
                  <b>
                    Display Group:
                  </b> {{file.displayGroup}}
                </p>
                <p class="m-0">
                  <b>
                    Display Type:
                  </b> {{file.displayType}}
                </p>
                <p class="m-0">
                  <b>Responsible:</b> {{file.responsible}}
                </p>
                <p class="m-0">
                  <b>Subject:</b> {{file.subject}}
                </p>
                <p class="m-0">
                  <b>Competitor:</b> {{file.competitorTag}}
                </p>
                <p class="m-0">
                  <b>Visit date:</b> {{file.visitDate}}
                </p>

                <!--<p class="m-0">
    <b>Created Date:</b> {{file.creationDate  | date: 'MM/dd/yyyy'}}
  </p>-->
                <p class="m-0" [hidden]="!file.latitude">
                  <b>Geolocation:</b> <a href="https://www.waze.com/ul?ll={{file.latitude}},{{file.longitude}}&navigate=yes&zoom=17" target="_blank"><img src="../../../assets/layout/images/iconWaze.png" style="width: 50px;" /></a> <a href="https://www.google.com/maps/search/?api=1&query={{file.latitude}},{{file.longitude}}" target="_blank"><img src="../../../assets/layout/images/iconGM.png" style="width: 60px;" /></a>
                </p>
                <div class="grid p-fluid noPrintable">
                  <div class="md:col-4">
                    <b>Add to print</b>
                  </div>
                  <div class="md:col-8">
                    <p-inputSwitch [(ngModel)]="file.checked"></p-inputSwitch>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <p-dialog header="{{imageSelected.imageTitle}}" [(visible)]="visible" [modal]="true" [style]="{ width: '50vw' }" [draggable]="false" [resizable]="false">

            <div class="grid p-fluid">
              <div class="field col-12 md:col-6">
                <img src="{{imageSelected.url}}" style="width: 100%" />
              </div>
              <div class="field col-12 md:col-6">
                <p class="m-0">
                  <b>Title:</b> {{imageSelected.imageTitle}}
                </p>
                <p class="m-0">
                  <b>POS:</b> {{imageSelected.pos}}
                </p>
                <p class="m-0">
                  <b>Customer:</b> {{imageSelected.customer}}
                </p>
                <p class="m-0">
                  <b>Description:</b> {{imageSelected.descriptionDocument}}
                </p>
                <p class="m-0">
                  <b>Account number:</b> {{imageSelected.accountNumber}}
                </p>
                <p class="m-0">
                  <b>Country:</b> {{imageSelected.country}}
                </p>
                <p class="m-0">
                  <b>City:</b> {{imageSelected.city}}
                </p>
                <p class="m-0">
                  <b>State:</b> {{imageSelected.state}}
                </p>
                <p class="m-0">
                  <b>Pos Region:</b> {{imageSelected.posRegionName}}
                </p>
                <p class="m-0">
                  <b>Channel:</b> {{imageSelected.posChannel}}
                </p>
                <p class="m-0">
                  <b>Sub Channel:</b> {{imageSelected.posSubChannel}}
                </p>
                <p class="m-0">
                  <b>Channel Segment:</b> {{imageSelected.posChannelSegment}}
                </p>
                <p class="m-0">
                  <b>Format:</b> {{imageSelected.format}}
                </p>
                <p class="m-0">
                  <b>Category:</b> {{imageSelected.category}}
                </p>
                <p class="m-0">
                  <b>
                    Module:
                  </b> {{imageSelected.module}}
                </p>
                <p class="m-0">
                  <b>
                    Activity:
                  </b> {{imageSelected.activity}}
                </p>
                <p class="m-0">
                  <b>Subject:</b> {{imageSelected.subject}}
                </p>
                <p class="m-0">
                  <b>Responsible:</b> {{imageSelected.responsible}}
                </p>
                <p class="m-0">
                  <b>Type:</b> {{imageSelected.type}}
                </p>
                <p class="m-0">
                  <b>Visit date:</b> {{imageSelected.visitDate}}
                </p>
                <!--<p class="m-0">
    <b>Visit description:</b> {{imageSelected.description}}
  </p>-->

                <p class="m-0">
                  <b>Competitor:</b> {{imageSelected.competitor}}
                </p>
                <p class="m-0">
                  <b>Status:</b> {{imageSelected.status}}
                </p>
                <p class="m-0">
                  <b>Start time:</b> {{imageSelected.startTime | date: 'yyyy-MM-dd hh:mm:ss'}}
                </p>
                <p class="m-0">
                  <b>End time:</b> {{imageSelected.endTime | date: 'yyyy-MM-dd hh:mm:ss'}}
                </p>
                <p class="m-0">
                  <b>Duration minutes:</b> {{imageSelected.durationMinutes}}
                </p>
                <!--<p class="m-0">
    <b>Duration effective:</b> {{imageSelected.durationEfective}}
  </p>-->
                <p class="m-0">
                  <b>Usage:</b> {{imageSelected.usage}}


                </p>




                <!--<p class="m-0">
    <b>Area Tags:</b> {{imageSelected.areaTag}}
  </p>
  <p class="m-0">
    <b>Competitor Tags:</b> {{imageSelected.competitorTag}}
  </p>
  <p class="m-0">
    <b>Pos Type Tags:</b> {{imageSelected.posTypeTag}}
  </p>
  <p class="m-0">
    <b>Tags:</b> {{imageSelected.tags}}
  </p>-->
                <p class="m-0" [hidden]="!imageSelected.latitude">
                  <b>Geolocation:</b> <a href="https://www.waze.com/ul?ll={{imageSelected.latitude}},{{imageSelected.longitude}}&navigate=yes&zoom=17" target="_blank"><img src="../../../assets/layout/images/iconWaze.png" style="width: 50px;" /></a> <a href="https://www.google.com/maps/search/?api=1&query={{imageSelected.latitude}},{{imageSelected.longitude}}" target="_blank"><img src="../../../assets/layout/images/iconGM.png" style="width: 60px;" /></a>
                </p>
              </div>

            </div>
          </p-dialog>
        </div>
    </div>
  </div>
</div>
<p-toast position="bottom-right" key="message" class="custom-toast"></p-toast>

