import { Component, Inject, ChangeDetectorRef } from '@angular/core';
//import { Token } from '@angular/compiler';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
//import oktaConfig from '../app/app.config';
import { Router } from '@angular/router';
//import { filter, map, Observable } from 'rxjs';
import { environment } from '../../environments/environment';

const oktaAuth = new OktaAuth({
  ...environment.authConfig
});

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss']
})
export class AuthenticationComponent {
  isAuthenticated2: any = null;
  statusAuthentication = 'a';
  user: any = {};

  constructor(@Inject(OKTA_AUTH) private _oktaAuth: OktaAuth, private route: Router, private cdref: ChangeDetectorRef) { }

  async ngOnInit() {

    localStorage.setItem("User", '');

    var authClient = oktaAuth;

    if (authClient.isLoginRedirect()) {
      try {
        await authClient.handleLoginRedirect();
      }
      catch (e: any) {
        if (e.errorCode === 'access_denied')
          this.statusAuthentication = 'd';
        else
          this.statusAuthentication = 'c';
      }

      this.isAuthenticated2 = await this._oktaAuth.isAuthenticated();
      if (this.isAuthenticated2) {
        this.user = await this._oktaAuth.getUser();
        console.log(this.user);
        // this.route.navigate(['targets/targets-distribution']);
       
           // localStorage.setItem("User", JSON.stringify(data));

            var navigation = sessionStorage.getItem("Navigation");

            if (navigation !== null && navigation !== '')
              this.route.navigate([navigation]);
            else
              this.route.navigate(['browser/images']);
         

      }
    }
    else
      await this._oktaAuth.signInWithRedirect();
  }

  async login() {
    await this._oktaAuth.signInWithRedirect();
  }
}
