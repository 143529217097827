import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppLayoutModule } from './layout/app.layout.module';
import { Routes, RouterModule, Router } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'

import { OktaAuthModule, OKTA_CONFIG, OktaAuthGuard, OktaCallbackComponent } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { environment } from '../environments/environment';
import { AuthInterceptor } from './auth.interceptor';

import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { MessageService } from 'primeng/api';
import { MultiSelectModule } from 'primeng/multiselect';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SkeletonModule } from 'primeng/skeleton';
import { ToastModule } from 'primeng/toast';

import { AppComponent } from './app.component';
import { SearchComponent } from './search/search/search.component';
import { AuthenticationComponent } from './authentication/authentication.component';
import { LogoutComponent } from './logout/logout.component';

const oktaAuth = new OktaAuth({
  ...environment.authConfig
});

@NgModule({
  declarations: [
    AppComponent,
    SearchComponent,
    AuthenticationComponent,
    LogoutComponent
  ],
  imports: [
    BrowserModule, HttpClientModule,
    AppRoutingModule,
    AppLayoutModule,
    FormsModule,
    CalendarModule,
    CheckboxModule,
    DialogModule,
    DropdownModule,
    InputSwitchModule,
    MessageModule,
    MessagesModule,
    MultiSelectModule,
    ProgressSpinnerModule,
    SkeletonModule,
    ToastModule,
    OktaAuthModule.forRoot({ oktaAuth }),
    NgIdleKeepaliveModule.forRoot()
  ],
  providers: [MessageService,
    OktaAuthGuard,
    { provide: OKTA_CONFIG, useValue: { oktaAuth } },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ], 
  bootstrap: [AppComponent]
})
export class AppModule { }
