import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { LayoutService } from './service/app.layout.service';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

    model: any[] = [];

    constructor(public layoutService: LayoutService) { }

    ngOnInit() {
        this.model = [
            /*{
                label: 'Portfolios',
                items: [
                    { label: 'Portfolios', icon: 'pi pi-fw pi-angle-double-right', routerLink: [''] },
                ]
            },*/
            {
                label: 'Images',
                items: [
                    { label: 'Search', icon: 'pi pi-fw pi-angle-double-right', routerLink: [''] }
                ]
          },
          {
            label: 'Administration',
            items: [
            ]
          }
        ];
    }
}
