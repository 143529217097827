
<div class="surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
  <div class="flex flex-column align-items-center justify-content-center">
    <div style="border-radius:56px; padding:0.3rem; background: linear-gradient(180deg, rgba(247, 149, 48, 0.4) 10%, rgba(247, 149, 48, 0) 30%);">
      <div class="w-full surface-card py-8 px-5 sm:px-8 flex flex-column align-items-center" style="border-radius:53px">
        <div class="grid flex flex-column align-items-center">
          <div class="flex justify-content-center align-items-center bg-orange-500 border-circle" style="width:3.2rem; height:3.2rem;">
            <i class="text-50 pi pi-fw pi-lock text-2xl"></i>
          </div>
          <img src="assets/layout/images/logoKC.jpg" alt="Image" height="70" class="mb-3">
          <h1 class="text-900 font-bold text-4xl lg:text-5xl mb-2">You have been disconnected</h1>
          <span class="text-600 mb-5">You have been inactive  more than 20 minutes, press the button if you want to continue using the application.</span>
          <p-button label="Log In" severity="success" (click)="login();"></p-button>
        </div>
      </div>
    </div>
  </div>
</div>

