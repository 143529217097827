<div class="layout-wrapper" [ngClass]="containerClass">
  <app-topbar></app-topbar>
  <!--<div class="layout-sidebar noPrintable">
    <app-sidebar></app-sidebar>
  </div>-->
  <div class="layout-main-container1" style="padding: 7rem 2rem 2rem;">
    <div class="layout-main">
      <router-outlet></router-outlet>
    </div>
    <app-footer></app-footer>
  </div>
  <app-config></app-config>
  <div class="layout-mask noPrintable"></div>
</div>

<style>
  @media print {
    .noPrintable {
      display: none;
    }
  }
</style>
