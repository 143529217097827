import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChannelSegmentsService {

  private _url = environment.ApiUrl + "/channelSegments";
  private _params = { }
  private _headers = { 'content-type': 'application/json' }

  constructor(private http: HttpClient) { }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error);
  }

  get(): Observable<any> {
    const headers = this._headers;
    const params = this._params
    return this.http.get<any>(this._url, { 'headers': headers, params }).pipe(catchError(this.errorHandler));
  }

}

