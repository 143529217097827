//import { Injectable } from '@angular/core';
//import { HttpClient, HttpErrorResponse } from '@angular/common/http';
//import { Observable, throwError } from 'rxjs';
//import { catchError } from 'rxjs/operators';


//@Injectable({
//  providedIn: 'root'
//})
//export class PosRegionsService {

//  private _url = "/api/posRegions/";

//  constructor(private http: HttpClient) { }

//  errorHandler(error: HttpErrorResponse) {
//    return throwError(error);
//  }

//  get(): Observable<any> {
//    const headers = { 'content-type': 'application/json' };
//    const body = JSON.stringify({
//    });
//    return this.http.post<any>(this._url + 'get', body, { 'headers': headers }).pipe(catchError(this.errorHandler));
//  }

//}

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class PosRegionsService {

  private _url = environment.ApiUrl + "/posRegions";
  private _params = { }
  private _headers = { 'content-type': 'application/json' }

  constructor(private http: HttpClient) { }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error);
  }

  get(): Observable<any> {
    const headers = this._headers;
    const params = this._params
    return this.http.get<any>(this._url, { 'headers': headers, params }).pipe(catchError(this.errorHandler));
  }

}
