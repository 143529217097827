<router-outlet></router-outlet>
<p-toast key="c" position="bottom-right" class="p-button-warning" sticky="true" severity='warn'>
  <ng-template let-message pTemplate="message">
    <div class="flex flex-column" style="flex: 1">
      <div class="text-center">
        <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>

        <p><b>No activity detected</b></p>
        <p>You will be disconnected in {{countdown}} seconds</p>
        <br />
      </div>
    </div>
  </ng-template>
</p-toast>