import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Inject, Component, OnInit } from '@angular/core';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { MessageService, ConfirmEventType } from 'primeng/api';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
//import oktaConfig from '../app/app.config';
import { Router } from '@angular/router';
import { environment } from '../environments/environment';

const oktaAuth = new OktaAuth({
  ...environment.authConfig
});

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  idleState = "NOT_STARTED";
  countdown?: any = null;
  lastPing?: any = null;

  showWarning: boolean = false;

  constructor(@Inject(OKTA_AUTH) private _oktaAuth: OktaAuth, private route: Router, private idle: Idle, keepalive: Keepalive, cd: ChangeDetectorRef, private messageService: MessageService) {
   
    // set idle parameters
    idle.setIdle(1210); // how long can they be inactive before considered idle, in seconds
    idle.setTimeout(120); // how long can they be idle before considered timed out, in seconds, 120
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); // provide sources that will "interrupt" aka provide events indicating the user is active

    // do something when the user becomes idle
    idle.onIdleStart.subscribe(() => {
      this.idleState = "Connected";
      //this.showWarn();
      cd.detectChanges(); // how do i avoid this kludge?
    });
    // do something when the user is no longer idle
    idle.onIdleEnd.subscribe(() => {
      this.idleState = "Not connected";
     // this.show3();
     // console.log(`${this.idleState} ${new Date()}`)
      this.countdown = null;
      cd.detectChanges(); // how do i avoid this kludge?
    });
   
    idle.onTimeoutWarning.subscribe((countdown) => {
      this.countdown = countdown;
      console.log('Auto log out in ' + countdown + ' seconds!');
      if (!this.showWarning) {
        this.showWarn();
      }
      this.showWarning = true;
      //this.idleTimer = 'Auto log out in ' + countdown + ' seconds!';
   });


    idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.';
      this.messageService.clear();
      this.showWarning = false;
      cd.detectChanges();
    });

    idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      console.log(this.idleState);
      this.messageService.clear();
      this.showWarning = false;
      this.signOut();
      cd.detectChanges();
    });

    // set keepalive parameters, omit if not using keepalive
    keepalive.interval(15); // will ping at this interval while not idle, in seconds
    keepalive.onPing.subscribe(() => this.lastPing = new Date()); // do something when it pings
  }

  reset() {
   this.idle.watch();
    this.idleState = "NOT_IDLE";
    this.countdown = null;
    this.lastPing = null;
  }

  ngOnInit(): void {
    this.reset();
  }

  showWarn() {
    this.messageService.add({ key: 'c', sticky: true, severity: 'warn', summary: 'test', detail: '2' });
  }

  signOut() {
    this._oktaAuth.signOut();
  }

  title = 'Image Broser Application';
}

