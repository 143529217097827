<div class="layout-topbar noPrintable">
  <a class="layout-topbar-logo" routerLink="">
    <!-- <img src="assets/layout/images/{{layoutService.config.colorScheme === 'light' ? 'logo-dark' : 'logo-white'}}.svg" alt="logo">-->
    <span>BANK OF IMAGES</span>
  </a>

  <button #menubutton class="p-link layout-menu-button layout-topbar-button" (click)="layoutService.onMenuToggle()">
    <i class="pi pi-bars"></i>
  </button>

  <button #topbarmenubutton class="p-link layout-topbar-menu-button layout-topbar-button" (click)="layoutService.showProfileSidebar()">
    <i class="pi pi-ellipsis-v"></i>
  </button>
</div>
<style>
  @media print {
    .noPrintable {
      display: none;
    }
  }
</style>
