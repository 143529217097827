
<div class="surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden" *ngIf="statusAuthentication === 'a'">
  <div class="flex flex-column align-items-center justify-content-center">
    <div style="border-radius:56px; padding:0.3rem; background: linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%);">
      <div class="w-full surface-card py-8 px-5 sm:px-8" style="border-radius:53px">

        <div class="grid flex flex-column align-items-center">
          <div class="flex justify-content-center align-items-center" style="height:3.2rem; width:3.2rem;">
            <p-progressSpinner styleClass="w-3rem h-3rem" strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
          </div>
          <img src="assets/layout/images/logoKC.jpg" alt="Image" height="70" class="mb-3">
          <h1 class="text-900 text-3xl font-medium mb-3">Welcome to Image Browser Application</h1>
          <span class="text-600 mb-5"> Authenticating please wait ...</span>
        </div>


      </div>
    </div>
  </div>
</div>
<div class="surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden" *ngIf="statusAuthentication === 'c'">
  <div class="flex flex-column align-items-center justify-content-center">
    <div style="border-radius:56px; padding:0.3rem; background: linear-gradient(180deg, rgba(233, 30, 99, 0.4) 10%, rgba(33, 150, 243, 0) 30%);">
      <div class="w-full surface-card py-8 px-5 sm:px-8 flex flex-column align-items-center" style="border-radius:53px">
        <div class="grid flex flex-column align-items-center">
          <div class="flex justify-content-center align-items-center bg-pink-500 border-circle" style="height:3.2rem; width:3.2rem;">
            <i class="pi pi-fw pi-exclamation-circle text-2xl text-white"></i>
          </div>
          <img src="assets/layout/images/logoKC.jpg" alt="Image" height="70" class="mb-3">
          <h1 class="text-900 font-bold text-5xl mb-2">Error Occured</h1>
          <span class="text-600 mb-5">Requested resource is not available.</span>
          <button pButton pRipple label="Try again" class="p-button-text" (click)="login();"></button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden" *ngIf="statusAuthentication === 'd'">
  <div class="flex flex-column align-items-center justify-content-center">
    <div style="border-radius:56px; padding:0.3rem; background: linear-gradient(180deg, rgba(247, 149, 48, 0.4) 10%, rgba(247, 149, 48, 0) 30%);">
      <div class="w-full surface-card py-8 px-5 sm:px-8 flex flex-column align-items-center" style="border-radius:53px">
        <div class="grid flex flex-column align-items-center">
          <div class="flex justify-content-center align-items-center bg-orange-500 border-circle" style="width:3.2rem; height:3.2rem;">
            <i class="text-50 pi pi-fw pi-lock text-2xl"></i>
          </div>
          <img src="assets/layout/images/logoKC.jpg" alt="Image" height="70" class="mb-3">
          <h1 class="text-900 font-bold text-4xl lg:text-5xl mb-2">Access Denied</h1>
          <span class="text-600 mb-5">You do not have the necessary permisions. Please contact admins.</span>
        </div>
      </div>
    </div>
  </div>
</div>

