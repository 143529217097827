<div class="layout-footer noPrintable" >
    <!--<img src="assets/layout/images/{{layoutService.config.colorScheme === 'light' ? 'logo-dark' : 'logo-white'}}.svg" alt="Logo" height="20" class="mr-2"/>-->
    Kimberly Clark
    <span class="font-medium ml-2">
        Copyright © 2024
        Version 1.0</span>
</div>
<style>
  @media print {
    .noPrintable {
      display: none;
    }
  }
</style>
