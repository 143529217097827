export const environment = {
   ApiUrl: 'https://us3np.apis.kcc.com/kcc-imagebrower-exp-api-initial-dev/api',
 //ApiUrl:'http://localhost:8081/api',
//Authorization: 'Basic MG9hZTlsaXJkNHFWdVgxSDkwaDc6Uy1PWHMwaW5xbEplNDluQkVocmtXT1NRWlYzWmRQV1Q5cURNcXlWcw==',
  production: false,
  authConfig: {
    clientId: '0oa1x873yacRdR63A0h8',
    issuer: 'https://kcc.oktapreview.com/oauth2/auskmljr5sgIXNbPx0h7',
    redirectUri: window.location.origin + '/',
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    useInteractionCodeFlow: false,
    useClassicEngine: true,
    postLogoutRedirectUri: 'https://dev.salesforceimage.app.kimclark.com/logout',
    tokenManager: {
      storage: 'sessionStorage'
    }
  }
};


