import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OktaAuthGuard, OktaCallbackComponent } from '@okta/okta-angular';
import { AppLayoutComponent } from "./layout/app.layout.component";
import { SearchComponent } from './search/search/search.component';
import { AuthenticationComponent } from './authentication/authentication.component';
import { LogoutComponent } from './logout/logout.component';

const routes: Routes = [];

@NgModule({
  imports: [
    RouterModule.forRoot([
        {
        path: 'browser', component: AppLayoutComponent,
            children: [
              { path: 'images', component: SearchComponent, canActivate: [OktaAuthGuard] }
            ]
      },
      { path: '', component: AuthenticationComponent },
      { path: 'logout', component: LogoutComponent }
    ])
],
  exports: [RouterModule],
  providers: [OktaAuthGuard],
})
export class AppRoutingModule { }
